import { RouteInfo } from './sidebar.metadata';
import { promise } from 'protractor';
import { AdminGuard } from '../auth/admin.guard';

//Sidebar menu Routes and data
export const ADMINROUTES: RouteInfo[] = [
    
   
    { path: '/calendar', title: 'Dashboard', icon: 'ft-calendar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    
    {
        path: '', title: 'Admin', icon: 'ft-user', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/forms/viewuser', title: 'User Association', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/forms/validation', title: 'Roster Site', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

        ]
        },

    
    {
        path: '', title: 'Report', icon: 'ft-aperture', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
            { path: '/report/incidentreport', title: 'Incident Report', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/report/logbookreport', title: 'Logbook Report', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/report/kpireport', title: 'KPI Report', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            //{ path: '/report/guardreport', title: 'Guard Zone Hits Report', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        ]
    },
    //{ path: '/forms/viewuser', title: 'UserAssociation', icon: 'has-sub', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    {
        
        path: '', title: 'Forms', icon: 'ft-edit', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-primary float-right mr-1 mt-1', isExternalLink: false,
        submenu: [
            { path: '/forms/logbook', title: 'Daily Log', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/forms/incident', title: 'Incident Log', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/forms/fire-alarm-management', title: 'Fire Alarm', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/forms/cctv-review-request', title: 'CCTV Review Request', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/forms/monthly-contractor', title: 'Meeting Minutes', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/forms/staff-personal-register', title: 'Personnel Register', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/forms/staff-training-register', title: 'Training Register', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
            { path: '/forms/staff-scenario-register', title: 'Scenario Training', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        
        ]
    },

    
    // badge badge-pill badge-primary float-right mr-1 mt-1

    // {
    //     path: '/forms/view-contact-records', title: 'Contact Centre', icon: 'ft-box', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    //     submenu: []
    //     //     { path: '/forms/rostering', title: 'Rostering', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     //     { path: '/forms/colliers', title: 'Colliers', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     //     { path: '/forms/knight-frank', title: 'Knight-Frank', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     //     { path: '/forms/kingsmede', title: 'Kingsmede', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     //     { path: '/forms/city-of-sydney', title: 'City of Sydney', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     //     { path: '/forms/daisho', title: 'Daisho', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     //     { path: '/forms/centuria', title: 'Centuria', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     //     { path: '/forms/tim-green-commercial', title: 'Tim Green Commercial', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     //     { path: '/forms/amp', title: 'AMP', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     //     { path: '/forms/logos', title: 'Logos', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     //     { path: '/forms/uniting-communities', title: 'Uniting Communities', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    //     // ]
    // },
    { path: '/forms/view-contact-records', title: 'Contact Center', icon: 'ft-box', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
 
   
   // { path: '/taskboard', title: 'Teams', icon: 'ft-file-text', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
 
    {
        path: '', title: 'KPI', icon: 'ft-grid', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
        // { path: '/tables/regular', title: 'Regular', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/tables/extended', title: 'Monthly Report', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { path: '/tables/smart', title: 'Summary', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        
        
        ]
        },

        { path: '/tables/view-documentation', title: 'Documentation', icon: 'ft-book', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      
        // { path: '/tables/regular', title: 'Documentation', icon: 'ft-book', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] }
];
