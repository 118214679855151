import { Injectable } from "@angular/core";
import { HttpClient, HttpHandler, HttpHeaders } from "@angular/common/http";

import * as Msal from "msal";
import { Observable } from "rxjs";
import { Logbook } from "app/Model/LogBook";
import { environment } from "environments/environment";
import { SecurityLicenceDetails, UserAssociation } from "app/Model/User";

export class Employee {
  id: number;
  name: string;
  company: string;
  city: string;
}

@Injectable({
  providedIn: "root",
})
export class UserService {
  API: string;
  obj: object;
  Data: object;
  UserId: string;

  public clientApplication: Msal.UserAgentApplication = null;
  private accessToken: any;
  //#region Endpoints

  log_Url = "api/oauth/login";
  signUp_Url = "api/accounts/create";
  getuserbyid_Url = "api/employees/getuserbyid";
  forgotpassword_Url = "api/employees/forgotpassword";
  changepassword_Url = "api/accounts/changepassword";
  addincidentform_Url = "api/IncidentReport/addincidentreport";
  adddailylogform_Url = "api/LogBook/addlogbook";
  getlogbookbook_Url = "api/LogBook/getlogbook?pageNumber=";
  deletelogbook_Url = "api/LogBook/deletelogbook";
  getincidentreport_Url = "api/incidentreport/getincidentreport?pageNumber=";
  deleteincidentreport_Url = "api/incidentReport/deleteincidentreport";
  getlogbookincidentcategory_Url = "api/LogBook/getlogbookincidentcategory";
  getincidentreportbyid_Url = "api/incidentReport/getincidentreportbyid";
  updateincidentreport_Url = "api/IncidentReport/updateincidentreport";
  updatelogbook_Url = "api/LogBook/editlogbook";
  getlogbookbyid_URl = "api/LogBook/getlogbookbyid";
  updateincidentreportpdf_Url = "api/IncidentReport/generateincidentreportpdf";
  generatelogbookpdf_Url = "api/LogBook/generatelogbookpdf";

  //user form url
  getalluser_Url = "api/MyDirectory/users";
  adduser_Url = "api/MyDirectory/users";
  deletuser_Url = "api/user/deleteuser?userId=";
  excel_Url = "api/user/addbulkuser";
  updateuser_Url = "api/user//updateuser";

  //get r2 sites data
  getr2sitesdata_Url = "api/UserCustomerAssociation/getallr2sitesdata";

  //GetLogBookDataByToday
  getlogbookdatabytoday_Url = "api/LogBook/getlogbookbytoday";
  //GetIncidentDataByToday
  getincidentreportDateData_Url = "api/incidentreport/getincidentreporttoday";

  //getlatestpost
  getlatestpostdocument_Url = "api/incidentreport/getalldocumentspost";

  //Customer
  getallcustomers_Url = "api/Customer/getallcustomers";
  getallcustomersgroups_Url = "api/Customer/getallcustomersgroups";
  getallcustomerbyid_Url = "api/Customer/getallcustomersbyid";
  deletecustomer_Url = "api/Customer/deletecustomer";
  addcustomer_Url = "api/Customer/addcustomer";
  updatecustomer_Url = "api/Customer/updatecustomer";

  //user customer association
  addusercustomerassociation = "api/UserCustomerAssociation/adduca";
  getucafordropdown = "api/UserCustomerAssociation/getucafordropdown";
  getallucarecord = "api/UserCustomerAssociation/getallucarecord";
  filteruca = "api/UserCustomerAssociation/filterucarecord";
  deleteassociation = "api/UserCustomerAssociation/deleteassociation";
  getucarecordbyId = "api/UserCustomerAssociation/getucabyid";
  getdefaultrostersitebyId =
    "api/UserCustomerAssociation/getdefaultrostersitebyid";
  getsiteemployee = "api/Deputy/getsiteemployee?SiteId=";

  getqrpetrolclients = "api/QRPetrol/getqrpetrolclients";

  //fetch all customer data for dropdown list
  fetchallcustomerdata_Url = "api/GuardHouse/fetchGuardHouseCompanies";

  guardhouseshiftbysite = "api/GuardHouse/getGuardHouseShiftsBySiteId";
  //email
  sendkpireport_url = "api/Email/sendkpireportonemail";
  sendlogbookreport_url = "api/Email/sendemailtousers";
  sendincidentreport_url = "api/Email/sendincidentreportonemail";

  //get user on behalf of id
  getusernamebyId = "api/MyDirectory/users/";

  //getdatafordropdownr2
  getr2fordropdown_Url = "api/UserCustomerAssociation/getr2datafordropdown";
  getqrfordropdown_Url = "api/UserCustomerAssociation/getqrdatafordropdown";
  getqrpatrolfordropdown_Url = "api/QRPetrol/getqrpetrolsites";

  //filter incident and logbook by date
  filterlogbookbookbydate_Url = "api/LogBook/filterlogbookrecordsbydate";
  filterincidentbydate_Url = "api/IncidentReport/filterincidentrecordsbydate";

  /*2022 06 02 John Vinson*/
  GetAllMonthlyReportBySiteId_Url = "api/BiApi/getallmonthlyreportsbysiteid";

  //get powerBI reports
  getembedreport_url = "api/BiApi/GetEmbedReport";
  // getembedreportincident_url = "api/BiApi/GetEmbedReportIncident";
  // getembedreportlogbook_url = "api/BiApi/GetEmbedReportLogBook";
  // getembedreportkpi_url = "api/BiApi/GetEmbedReportKpi";
  //#endregion
  editId: any;
  editSiteId: any;
  editSiteName: any;
  userservice: any;
  UserRole: any;
  GuardhouseCompany: any;

  constructor(private http: HttpClient) {
    //this.API='http://localhost:59822/';
    // this.API = 'http://localhost:58980/';
    this.API = environment.baseUrl;
    // this.clientApplication = new Msal.UserAgentApplication(
    //   environment.uiClienId,
    //   'https://login.microsoftonline.com/' + environment.tenantId,
    //   this.authCallback,
    //   {
    //       storeAuthStateInCookie: true,
    //       //cacheLocation: 'localStorage' ,
    //   });
  }

  public authCallback(errorDesc, token, error, tokenType) {
    if (token) {
    } else {
      console.log(error + ":" + errorDesc);
    }
  }

  public GetAccessToken(): Observable<any> {
    if (
      sessionStorage.getItem("msal.idtoken") !== undefined &&
      sessionStorage.getItem("msal.idtoken") != null
    ) {
      this.accessToken = sessionStorage.getItem("msal.idtoken");
    }
    return this.accessToken;
  }
  // public getCurrentUserInfo() {
  //   const user = this.clientApplication.getUser();
  //   alert(user.name);
  // }

  public logout() {
    this.clientApplication.logout();
  }

  public login(credential) {
    var data = {
      UserName: credential.email,
      Password: credential.password,
    };
    return this.http.post(this.API + this.log_Url, data);
  }

  RegisterUser(data) {
    var sentObj = {
      Email: data.Email,
      UserName: data.FullName,
      Password: data.Password,
      ConfirmPassword: data.ConfirmPassword,
      FirstName: data.FullName,
      LastName: data.FullName,
      CompanyName: data.CompanyName,
      CompanyPhone: data.BusinessPhone,
      UserType: data.UserType,
    };
    return this.http.post(this.API + this.signUp_Url, sentObj);
  }

  getSecurityLicence(id: string) {
    debugger;
    return this.http.get(
      this.API + `api/usercustomerassociation/getSecurityLicence?id=${id}`
    );
  }
  addSecurityLicence(licenceDetails: SecurityLicenceDetails): Observable<any> {
    return this.http.post<SecurityLicenceDetails>(
      this.API + `api/usercustomerassociation/updateSecurityLicence`,
      licenceDetails
    );
  }
  GetGuardHouseShiftsBySiteId(body) {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post<any>(this.API + this.guardhouseshiftbysite, body);
  }
  GetUserById(Id) {
    var obj = "userId=" + Id;
    return this.http.get<any>(this.API + this.getuserbyid_Url + "?" + obj);
  }

  ForgetPassword(data) {
    var obj = "email=" + data.email;
    return this.http.get(this.API + this.forgotpassword_Url + "?" + obj);
  }

  UpdatePassword(data) {
    return this.http.post(this.API + this.changepassword_Url, data);
  }

  IncidentForm(data) {
    return this.http.post(this.API + this.addincidentform_Url, data);
  }

  LogForm(data) {
    return this.http.post(this.API + this.adddailylogform_Url, data);
    // return this.http.post("http://localhost:59822/api/LogBook/addlogbook",data)
    // return this.http.post<Logbook>('http://localhost:58980/api/LogBook/addlogbook', data)
  }

  GetEmbedReport(reportId) {
    var report = "ReportID=" + reportId;
    return this.http.get(this.API + this.getembedreport_url + "?" + report);
  }

  // GetEmbedReportIncident(reportId){
  //
  //   var report = "ReportID=" + reportId;
  //   return this.http.get(this.API + this.getembedreportincident_url + "?" + report);
  //   }

  //   GetEmbedReportLogBook(reportId){
  //
  //     var report = "ReportID=" + reportId;
  //     return this.http.get(this.API + this.getembedreportlogbook_url + "?" + report);
  //     }

  //     GetEmbedReportKpi(reportId){
  //
  //       var report = "ReportID=" + reportId;
  //       return this.http.get(this.API + this.getembedreportkpi_url + "?" + report);
  //       }

  GetLogBook(page, view) {
    this.UserId = localStorage.getItem("ActiveUser");

    return this.http.get(
      this.API +
        this.getlogbookbook_Url +
        page +
        "&userId=" +
        this.UserId +
        "&dataType=" +
        view
    );
  }

  GetTodayLogBookDataForDashboard(dates) {
    this.UserId = localStorage.getItem("ActiveUser");
    var role = localStorage.getItem("Role");
    var obj = "userId=" + this.UserId;
    return this.http.get(
      this.API +
        this.getlogbookdatabytoday_Url +
        "?" +
        obj +
        "&role=" +
        role +
        "&Date=" +
        dates
    );
  }

  GetTodayIncidentReportDataForDashboard(dates) {
    this.UserId = localStorage.getItem("ActiveUser");
    var role = localStorage.getItem("Role");
    var obj = "userId=" + this.UserId;
    return this.http.get(
      this.API +
        this.getincidentreportDateData_Url +
        "?" +
        obj +
        "&role=" +
        role +
        "&Date=" +
        dates
    );
  }

  DeleteLogBook(id) {
    this.obj = {
      Id: id,
      Deleted: true,
    };
    return this.http.put(this.API + this.deletelogbook_Url, this.obj);
  }

  GetIncidentReports(page, view) {
    this.UserId = localStorage.getItem("ActiveUser");
    return this.http.get(
      this.API +
        this.getincidentreport_Url +
        page +
        "&userId=" +
        this.UserId +
        "&dataType=" +
        view
    );
  }

  DeleteIncidentReports(id) {
    this.Data = {
      id: id,
      Deleted: true,
    };
    return this.http.put(this.API + this.deleteincidentreport_Url, this.Data);
  }

  UpdateIncidentReport(data) {
    return this.http.post(this.API + this.updateincidentreport_Url, data);
  }

  GetIncidentReportByID(ID) {
    var obj = "ID=" + ID;
    return this.http.get(this.API + this.getincidentreportbyid_Url + "?" + obj);
  }

  getAllIncidentCategory() {
    return this.http.get(this.API + this.getlogbookincidentcategory_Url);
  }

  //Fetch all deputy sites that are associated with a user
  GetGuardHouseOperationalUnits() {
    return this.http.get(
      this.API + "api/GuardHouse/getGuardHouseOperationalUnits"
    );
  }
  GetGuardhouseCompanyDetails(UserRole: string, UserId: string) {
    const body = { UserRole, UserId };
    console.log("Da Body", body);
    return this.http.post(this.API + this.fetchallcustomerdata_Url, body);
  }

  UpdateLogBook(logdata) {
    // var log ={
    //   "GuardName" : logdata.GaurdName,
    //   "OnDate" : logdata.Date,
    //   "Detail" : logdata.Occurrence,
    //   "Licensenumber" : logdata.SecurityLicence,
    //   "OnTime" : logdata.Time,
    //   "IsDeleted" : false
    // };
    return this.http.put(this.API + this.updatelogbook_Url, logdata);
  }

  getLogBookById(Id) {
    var obj = "Id=" + Id;
    return this.http.get(this.API + this.getlogbookbyid_URl + "?" + obj);
  }

  //User form methods

  public UserForm(data) {
    return this.http.post(this.API + this.adduser_Url, data);
  }

  public updateUserRecords(data) {
    return this.http.put(this.API + this.updateuser_Url, data);
  }

  public GetAllUser() {
    return this.http.get(this.API + this.getalluser_Url);
  }

  public DeletUser(userId) {
    return this.http.delete(this.API + this.deletuser_Url + userId);
  }

  //customer
  public GetAllCustomers() {
    return this.http.get(this.API + this.getallcustomers_Url);
  }

  public GetAllCustomerById(customerId) {
    var obj = "ID=" + customerId;
    return this.http.get(this.API + this.getallcustomerbyid_Url + "?" + obj);
  }

  public GetAllCustomersGroups() {
    return this.http.get(this.API + this.getallcustomersgroups_Url);
  }

  public DeleteCustomer(customerId) {
    var obj = "ID=" + customerId;
    return this.http.delete(this.API + this.deletecustomer_Url + "?" + obj);
  }

  public UpdateCustomerRecords(data) {
    return this.http.post(this.API + this.updatecustomer_Url, data);
  }

  public AddCustomerRecords(data) {
    return this.http.post(this.API + this.addcustomer_Url, data);
  }

  //pdf
  GenerateIncidentReportPdf(id) {
    var obj = "ID=" + id;
    return this.http.get(
      this.API + this.updateincidentreportpdf_Url + "?" + obj
    );
  }

  GenerateLogBookPdf(id) {
    var obj = "ID=" + id;
    return this.http.get(this.API + this.generatelogbookpdf_Url + "?" + obj);
  }

  GetLatestPostDocuments() {
    return this.http.get(this.API + this.getlatestpostdocument_Url);
  }

  //add bulk user
  public UploadExl(data) {
    return this.http.post(this.API + this.excel_Url, data);
  }

  AddUserCustomerAssociation(ucadata) {
    // this.obj = {
    //   "UserId": ucadata.user.id,
    //   "CustomerId": ucadata.customer.customerId,
    //   "UserName" :a,
    //   "CustomerName" : ucadata.customer.name
    //   }
    return this.http.post(this.API + this.addusercustomerassociation, ucadata);
  }
  //proper mapping

  getUcaById(Id): Observable<UserAssociation[]> {
    var obj = "Id=" + Id;
    return this.http.get<UserAssociation[]>(
      this.API + this.getucarecordbyId + "?" + obj
    );
  }
  //can remove this shit once fixed
  GetUCAById(Id) {
    var obj = "Id=" + Id;
    return this.http.get(this.API + this.getucarecordbyId + "?" + obj);
  }

  GetUCAForDropdown(Id) {
    var obj = "Id=" + Id;
    return this.http.get(this.API + this.getucafordropdown + "?" + obj);
  }

  //client Dropdown
  GetClientForDropdown() {
    return this.http.get(this.API + this.getqrpetrolclients);
  }

  GetR2DataForDropdown(Id) {
    var obj = "Id=" + Id;
    return this.http.get(this.API + this.getr2fordropdown_Url + "?" + obj);
  }

  GetQRPatrolForDropdown(Id) {
    var obj = "Id=" + Id;
    return this.http.get(this.API + this.getqrfordropdown_Url + "?" + obj);
  }

  GetQRPatrolDropdown(Id) {
    var obj = "Id=" + Id;
    return this.http.get(
      this.API + this.getqrpatrolfordropdown_Url + "?" + obj
    );
  }

  GetAllUCARecord() {
    return this.http.get(this.API + this.getallucarecord);
  }

  FilterUCA(ucadata) {
    return this.http.post(this.API + this.filteruca, ucadata);
  }

  DeleteAssociation(uca) {
    if (uca.companyId != 0) {
      this.obj = {
        UserId: uca.userId,
        CompanyId: uca.companyId,
        Defaultsites: uca.defaultsites,
        SiteType: uca.siteType,
      };
    } else {
      this.obj = {
        UserId: uca.userId,
        CustomerId: uca.customerId,
        Defaultsites: uca.defaultsites,
        SiteType: uca.siteType,
      };
    }
    if (uca.siteId != null) {
      this.obj = {
        UserId: uca.userId,
        SiteId: uca.siteId,
        Defaultsites: uca.defaultsites,
        SiteType: uca.siteType,
      };
    }

    return this.http.put(this.API + this.deleteassociation, this.obj);
  }

  GetUserInfo(Id) {
    //this.obj = {"Id" : Id}
    var obj = "Id=" + Id;
    return this.http.get(this.API + this.getusernamebyId + Id);
  }

  GetUCADefaultRosterSiteById(Id) {
    var obj = "Id=" + Id;
    return this.http.get(this.API + this.getdefaultrostersitebyId + "?" + obj);
  }

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  getSiteEmployee(SiteId) {
    return this.http.get(this.API + this.getsiteemployee + SiteId);
  }

  //R2sites Data
  public GetR2SitesData() {
    return this.http.get(this.API + this.getr2sitesdata_Url);
  }

  //Approved Leaves API
  GetDeputyEmployeeProfileById(Id) {
    return this.http.get(
      `${this.API}api/Deputy/getteamsprofilebyid?EmployeeID=${Id}`,
      Id
    );
  }

  //SendEmail
  public SendEmailAPI(data) {
    return this.http.post(this.API + this.sendlogbookreport_url, data);
  }
  public SendIncidentReportEmail(data) {
    return this.http.post(this.API + this.sendincidentreport_url, data);
  }
  public SendKPIReportEmail(data) {
    return this.http.post(this.API + this.sendkpireport_url, data);
  }

  GenerateFireAlarmPDF(id) {
    return this.http.get(
      `${this.API}api/FireAlarm/generatefirealarmpdf?ID=${id}`,
      id
    );
  }

  SendFireAlarmEmailReport(data) {
    return this.http.post(
      `${this.API}api/Email/sendfirealarmreportonemail`,
      data
    );
  }

  SendCCTVReportEmailPDF(data) {
    return this.http.post(`${this.API}api/Email/sendcctvreportonemail`, data);
  }

  FilterLogbookRecordsbydate(data) {
    this.UserId = localStorage.getItem("ActiveUser");
    var UserRole = localStorage.getItem("Role");
    if (data.startDate == "" || data.startDate == null) {
      this.Data = {
        SiteID: data.siteId,
        GuardName: data.guardName,
        userId: this.UserId,
        dataType: data.view,
        userRole: UserRole,
      };
    } else {
      this.Data = {
        startDate: data.startDate,
        endDate: data.endDate,
        SiteID: data.siteId,
        GuardName: data.guardName,
        userId: this.UserId,
        dataType: data.view,
        userRole: UserRole,
      };
    }
    return this.http.post(
      this.API + this.filterlogbookbookbydate_Url,
      this.Data
    );
  }

  /*2022 06 02 John Vinson*/
  GetAllMonthlyReportBySiteId(siteId) {
    return this.http.get(
      `${this.API}${this.GetAllMonthlyReportBySiteId_Url}?SiteId=${siteId}`
    );
  }

  FilterFireAlarmRecords(data) {
    this.UserId = localStorage.getItem("ActiveUser");
    if (data.startDate == "" || data.startDate == null) {
      this.Data = {
        SiteID: data.siteId,
        userId: this.UserId,
        role: data.role,
      };
    } else {
      this.Data = {
        startDate: data.startDate,
        endDate: data.endDate,
        SiteID: data.siteId,
        userId: this.UserId,
        role: data.role,
      };
    }
    return this.http.post(
      `${this.API}api/FireAlarm/filterfirealarmrecords`,
      this.Data
    );
  }

  FilterIncidentRecordsbydate(data) {
    this.UserId = localStorage.getItem("ActiveUser");
    var UserRole = localStorage.getItem("Role");
    if (data.startDate == "" || data.startDate == null) {
      this.Data = {
        SiteID: data.siteId,
        SecurityOfficer: data.securityOfficer,
        userId: this.UserId,
        dataType: data.view,
        userRole: UserRole,
      };
    } else {
      this.Data = {
        startDate: data.startDate,
        endDate: data.endDate,
        SiteID: data.siteId,
        SecurityOfficer: data.securityOfficer,
        userId: this.UserId,
        dataType: data.view,
        userRole: UserRole,
      };
    }
    return this.http.post(this.API + this.filterincidentbydate_Url, this.Data);
  }

  GetLogBookRecordsForAdmin(page, view) {
    this.UserId = localStorage.getItem("ActiveUser");
    return this.http.get(
      this.API +
        `api/LogBook/getlogbookdataadmin?pageNumber=` +
        page +
        "&userId=" +
        this.UserId +
        "&dataType=" +
        view
    );
  }

  GetIncidentRecordsForAdmin(page, view) {
    this.UserId = localStorage.getItem("ActiveUser");
    return this.http.get(
      this.API +
        `api/IncidentReport/getincidentreportforadmin?pageNumber=` +
        page +
        "&userId=" +
        this.UserId +
        "&dataType=" +
        view
    );
  }

  //CCTV API
  AddCCTVReviewData(obj) {
    return this.http.post(
      this.API + `api/CCTVReviewRequest/addcctvrecord`,
      obj
    );
  }

  GetAllCCTVRecords() {
    return this.http.get(this.API + `api/CCTVReviewRequest/getallcctvrecords`);
  }

  DeleteCCTVRecords(ID) {
    return this.http.put(
      this.API + `api/CCTVReviewRequest/deletecctvdata?ID=${ID}`,
      ""
    );
  }

  GetCCTVRecordsByID(ID) {
    return this.http.get(
      this.API + `api/CCTVReviewRequest/getcctvrecordsbyid?ID=${ID}`
    );
  }

  UpdateCCTVData(obj) {
    return this.http.put(
      this.API + `api/CCTVReviewRequest/updatecctvrecords`,
      obj
    );
  }

  GenerateCCTVPDF(id) {
    return this.http.get(
      `${this.API}api/CCTVReviewRequest/generatecctvreport?ID=${id}`,
      id
    );
  }

  //Rostering
  GetRostering(id) {
    return this.http.get(
      this.API + `api/ContactCentre/getcontactbyid?id=${id}`,
      id
    );
  }

  AddRostering(rosteringData) {
    return this.http.post(
      this.API + `api/ContactCentre/createrostering`,
      rosteringData
    );
  }

  GetAllContactRecords() {
    return this.http.get(this.API + `api/ContactCentre/getallcontactrecords`);
  }

  UpdateSingleContactRecord(employee) {
    return this.http.post(
      this.API + `api/ContactCentre/updatesinglecontactrecord`,
      employee
    );
  }

  DeleteSingleContactRecord(id) {
    return this.http.delete(
      this.API + `api/ContactCentre/deletesinglecontactrecord?id=${id}`,
      id
    );
  }

  SearchContactData(searchData) {
    if (searchData.startdate == "" || searchData.startdate == null) {
      this.Data = {
        contactname: searchData.contactname,
        client: searchData.client,
      };
    } else {
      this.Data = {
        startdate: searchData.startdate,
        enddate: searchData.enddate,
        contactname: searchData.contactname,
        client: searchData.client,
      };
    }
    return this.http.post(
      this.API + `api/ContactCentre/searchcontactdata`,
      this.Data
    );
  }

  public SendContactReportEmail(data) {
    return this.http.post(
      this.API + `api/Email/sendcontactcenterreportonemail`,
      data
    );
  }

  GetLogBookGuards() {
    var role = localStorage.getItem("Role");
    var UserId = localStorage.getItem("ActiveUser");
    return this.http.get(
      this.API +
        `api/LogBook/getlogbookguards?role=` +
        role +
        "&UserId=" +
        UserId
    );
  }

  GetIncidentGuards() {
    var role = localStorage.getItem("Role");
    var UserId = localStorage.getItem("ActiveUser");
    return this.http.get(
      this.API +
        `api/IncidentReport/getincidentsecuritylist?role=` +
        role +
        "&UserId=" +
        UserId
    );
  }

  GetMonthlyReportPDF(data) {
    return this.http.post(this.API + `api/BiApi/ExportPowerBiReport`, data);
  }

  GetMonthlyReportPDFForm(data) {
    return this.http.post(this.API + `api/BiApi/ExportPowerBiReportForm`, data);
  }

  GenerateSelectedRecordsPDF(docdata) {
    return this.http.post(
      this.API + `api/Email/generateselectedlogbookdatapdf`,
      docdata
    );
  }

  //Add Roster site
  AddRoasterSite(rosterData) {
    return this.http.post(
      this.API + `api/RoasterSite/createrostersite`,
      rosterData
    );
  }
  //Delete Roster site
  DeleteRoasterSiteRecord(id) {
    return this.http.delete(
      this.API + `api/RoasterSite/deleteroastersite?id=${id}`
    );
  }
  //Get All Roster site
  public GetAllRoasterSite() {
    return this.http.get(this.API + "api/RoasterSite/getallroastersitedata");
  }
  //Update Roster site
  public UpdateRoasterSite(data) {
    return this.http.put(`${this.API}api/RoasterSite/updateroastersite`, data);
  }

  //get monthly report
  GetallMonthlyreportsData() {
    return this.http.get(this.API + `api/BiApi/getallmonthlyreports`);
  }

  //staffpersonaldata
  getallStaffPersonalData(SiteId: any) {
    return this.http.get(
      this.API + `api/StaffPersonalRegister/getallbysiteid?siteId=${SiteId}`
    );
  }
  deleteStaffPersonalData(id: number) {
    return this.http.delete(
      this.API + `api/StaffPersonalRegister/delete?id=` + id
    );
  }
  updateStaffPersonalData(data: any) {
    return this.http.put(
      this.API + `api/StaffPersonalRegister/createupdate`,
      data
    );
  }

  //staff-training-data
  getallStaffTrainingData(SiteId: any) {
    return this.http.get(
      this.API + `api/StaffTrainingRegister/getall?siteId=${SiteId}`
    );
  }
  deleteStaffTrainingData(id: number) {
    return this.http.delete(
      this.API + `api/StaffTrainingRegister/delete?id=` + id
    );
  }
  updateStaffTrainingData(data: any) {
    return this.http.put(
      this.API + `api/StaffTrainingRegister/createupdate`,
      data
    );
  }

  //staffscenariodata
  getallStaffScenarioData(SiteId: any) {
    return this.http.get(
      this.API + `api/ScenarioTrainingRegister/getall?siteId=${SiteId}`
    );
  }
  deleteStaffScenarioData(id: number) {
    return this.http.delete(
      this.API + `api/ScenarioTrainingRegister/delete?id=` + id
    );
  }
  updateStaffScenarioData(data: any) {
    return this.http.put(
      this.API + `api/ScenarioTrainingRegister/createupdate`,
      data
    );
  }
}
