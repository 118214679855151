import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthService {
  token: string;
  API: string


  //GetAllCustomersAPI 
  getallcustomer_Url = 'api/Customer/getallcustomers'


  //DataBindingCalendarAPI
 // getallcalendardata_Url = 'api/Deputy/getdeputyemployeedetails';
 // getallcompanydetails_Url = 'api/Deputy/getdeputycompanydetails';
  //getallroles_Url = 'api/Deputy/getrolesbyCompany';
 // getcompanydetailsroles_Url = 'api/Deputy/getdeputyemployeedetailsbyposition';
  getallviewdata_Url = 'api/Deputy/getdatabyviews';
 // getallviewdataposition_Url = 'api/Deputy/getdataofviewsbyposition';
  getalldatamonthview_Url = 'api/Deputy/getdataofcurrentmonth';
  getkpi_Url = 'api/KPI/getkpidata';
  getassociatedsite_Url = 'api/Deputy/getassociatesitename?UserId=';
  updatekpi_Url = 'api/KPI/updatekpidata';
  savekpi_URL = 'api/KPI/addscore';
  getkpiscore_Url = 'api/KPI/getkpiscoredata?UserId=';
  getallkpiscore_Url = 'api/KPI/getallkpiscore?UserId=';
  getkpiscorepdf_Url = 'api/KPI/generatekpimonthlyreportpdf?UserId=';


  getsitesdataforadmin_Url = 'api/KPI/getkpiscoredataAdmin';

  //get company data by default
  getCompanyDataByDefault_Url = "api/Deputy/getdeputyemployeedetailsbydefault/";

  getallqrpetrolclient_Url = 'api/QRPetrol/getqrpetrolclients'

  constructor(private http: HttpClient) {
    //this.API = 'http://localhost:58980/';
    //this.API='http://localhost:59822/';
    this.API = environment.baseUrl;
  }


  //GEtCalendarMonthData
  GetCalendarMonthDataClick(CompanyID) {

    var obj = "CompanyID=" + CompanyID;
    return this.http.get(this.API + this.getalldatamonthview_Url + "?" + obj);
  }




  //GetViewsData
  GetViewsDataByView(View, SelectedDate, Company) {

    var obj = "Company=" + Company;
    var obj1 = "SelectedDate=" + SelectedDate;
    var obj2 = "View=" + View;
    return this.http.get(this.API + this.getallviewdata_Url + "?" + obj2 + "&" + obj1 + "&" + obj);
  }

  GetCompanyDataByDefault(Id) {

    var obj = "CompanyID=" + Id;
    return this.http.get<any>(this.API + this.getCompanyDataByDefault_Url + "?" + obj);
  }

  KPIScoreSave(data) {
    debugger;
    var obj = {
      "KpiList": data,
    }
    return this.http.post(this.API + this.savekpi_URL, obj);
  }

  UpdateKPIScoreData(data) {
    debugger;
    var obj = {
      "KpiList": data,
    }
    return this.http.post(this.API + this.updatekpi_Url, obj);
  }

  GenerateKPIPDF(data) {
    debugger;
    return this.http.get(this.API + this.getkpiscorepdf_Url + data.UserID + "&SiteId=" + data.title + "&Month=" + data.Month + "&Year=" + data.KpiYear);
  }


  //GetAllCustomersData
  GetAllCustomersData() {
    return this.http.get(this.API + this.getallcustomer_Url);
  }



  GetKPIDetail() {

    return this.http.get(this.API + this.getkpi_Url);
  }
  GetAssociatedCompany(UserId) {

    return this.http.get(this.API + this.getassociatedsite_Url + UserId);
  }

  GetKPIScore(data) {

    return this.http.get(this.API + this.getkpiscore_Url + data.UserID + "&SiteId=" + data.title + "&Month=" + data.Month + "&Year=" + data.KpiYear);
  }

  GetAllKPIScore(UserId, Type) {

    return this.http.get(this.API + this.getallkpiscore_Url + UserId + "&Type=" + Type);
  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
  }

  logout() {
    this.token = null;
  }

  getToken() {
    return this.token;
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token 
    return true;
  }

  /**
* @method addFireAlaram,getFireAlaram
* @param firealaramdata: It get the all info about user(first_name, last_name, password etc.)
* @author Harshal kadam
*/
  addFireAlaramData(data) {
    return this.http.post(`${this.API}api/FireAlarm/addfirealaram`, data);
  }

  getFireAlaramData() {
    var userId = localStorage.getItem('ActiveUser');
    var role = localStorage.getItem('Role');
    return this.http.get(`${this.API}api/FireAlarm/getfirealaram?role=${role}&userId=${userId}`);
  }

  deleteFireAlaram(id) {
    return this.http.put(`${this.API}api/FireAlarm/deletefirealaram?id=${id}`, id);
  }

  getAlarmData(id) {
    return this.http.get(`${this.API}api/FireAlarm/getfirealarambyid?id=${id}`, id);
  }

  updateAlarmData(updateData) {
    return this.http.put(`${this.API}api/FireAlarm/updatefirealaram`, updateData);
  }

  // Documentation API
  addDocumentData(data) {
    return this.http.post(`${this.API}api/Document/adddocument`, data);
  }

  
  public addMultipartDocument(file: File) {
    debugger;
    let formParams = new FormData();
    formParams.append('file', file)
    return this.http.post(`${this.API}api/Document/uploadfile`, formParams)
  }

   // Check Document Exist in DB API
   validateExistingDocuments(data) {
     debugger;
    return this.http.post(`${this.API}api/Document/checkuploadeddocuments`, data);
  }

  getDocument(id, role) {
    return this.http.get(`${this.API}api/Document/getdocument?UserId=${id}&Role=${role}`);
  }

  getDocumentById(UserId, SiteName, role) {
    return this.http.get(`${this.API}api/Document/getdocumentbyuserid?UserId=${UserId}&SiteName=${SiteName}&Role=${role}`);
  }

  getDocumentByFileUrl(docUrl) {
    var data = { DocUrl : docUrl }
    return this.http.post(`${this.API}api/Document/getdocumentbyfileurl`, data);
    //return this.http.get(`${this.API}api/Document/getdocumentbyfileurl?docUrl=${docUrl}`);
  }

  // Delete Document
  deleteDocument(ID) {
    return this.http.delete(`${this.API}api/Document/deletedocument?ID=${ID}`);
  }

  //Contact Center PDF
  GenerateContactPDF(pdfData) {
    debugger;
    return this.http.post(this.API + `api/ContactCentre/generatecontactpdf`, pdfData)
  }

  // add monthly contactractor
  addMonthlyContactractor(data) {
    return this.http.post(`${this.API}api/ContractorMeeting/addcontractormeetingdata`, data);
  }

  // get all monthly contactractor
  getMonthlyContactractor(userId) {
    return this.http.get(`${this.API}api/ContractorMeeting/getallcontractmeetingrecords?userId=${userId}`);
  }

  // delete monthly contractor
  deleteContractor(ID) {
    return this.http.delete(`${this.API}api/ContractorMeeting/deletecontractmeetingrecord?ID=${ID}`);
  }

  // get DATA by id
  getContractorById(id: number) {
    return this.http.get(`${this.API}api/ContractorMeeting/getcontractmeetingrecordbyid?ContractId=${id}`);
  }

  // update monthly contactractor
  updateMonthlyContactractor(data) {
    return this.http.put(`${this.API}api/ContractorMeeting/updatecontractormeetingdata`, data);
  }

  //Contact Center PDF
  GenerateMonthlyContactractorPDF(id) {
    return this.http.get(`${this.API}api/ContractorMeeting/generatecontractormeetingpdf?ID=${id}`)
  }

  // Email for monthly contactractor
  emailMonthlyContractor(data) {
    return this.http.post(`${this.API}api/Email/sendcontractorreportonemail`, data)
  }

   //GetAllCustomersData
   GetAllQRPetrolClient() {
    return this.http.get(this.API + this.getallqrpetrolclient_Url);
  }

  //download base64 to pdf
  downloadPdf(base64String, fileName) {
    debugger;
    const source = `data:application/pdf;base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`
    link.click();
  }

}
