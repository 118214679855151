import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    interval;

    constructor(private router: Router, private authService: MsalService) {
        
        if (!authService.getAccount()) {
        router.navigateByUrl('/pages/login')
        }
        }

    ngOnInit() {
        //this.intervalLogOut();
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));
            
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    //Logout fromapplication
    // intervalLogOut(){
    //     this.interval = setInterval(() => {
    //     var time = new Date().toLocaleString("en-US", { timeZone: "Australia/Sydney" }).split(", ")[1];
    //     debugger;
    //     if (time.split(":")[0] == "6" && time.split(":")[1] == "00") {
    //     this.authService.logout();
    //     }
    //     }, 1000)
    //     }


}