import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { MsalService } from '@azure/msal-angular';
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from 'user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  username : any;
  Role: any;
  layoutSub: Subscription;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();
  docStatus : boolean = false;
  associatedRecords = [];

  public config: any = {};

  constructor(public translate: TranslateService, 
    private layoutService: LayoutService, 
    private configService: ConfigService, 
    private authService: MsalService, 
    private router: Router, 
    private spinnerService: NgxSpinnerService,
    private userservice: UserService) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");

    this.layoutSub = layoutService.changeEmitted$.subscribe(
      direction => {
        const dir = direction.direction;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      });
  }

  ngOnInit() {
    this.username=localStorage.getItem("-UserName");
    this.Role = localStorage.getItem('Role');
    this.config = this.configService.templateConf;
    if (this.Role == 'SuperAdmin') {
      localStorage.setItem('DocumentStatus', 'true');
      this.docStatus = true;
    }
    else {
      if(this.Role == 'User')
      {
       this.checkSiteAssociatonForAdmin();
      }
      else if(this.Role == 'SiteManager')
      {
        this.checkSiteAssociatonForAdmin();
      }
      else if(this.Role == 'Rostering')
      {
        this.checkSiteAssociatonForAdmin();
      }
      else{
        localStorage.setItem('DocumentStatus', 'false');
        this.docStatus = false;
      }
      
    }
  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      setTimeout(() => {
        const dir = this.config.layout.dir;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      }, 0);

    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  checkSiteAssociatonForAdmin() {
    this.spinnerService.show();
    const loginID = localStorage.getItem('ActiveUser');
    this.userservice.GetUCAById(loginID).subscribe((data: any) => { 
      if (data != null && data != 0) {
        this.spinnerService.hide();
        this.associatedRecords = data;
        console.log("data", this.associatedRecords[0].documentationSite);
        this.docStatus = this.associatedRecords[0].documentationSite
        if(this.docStatus == true)
        {
          localStorage.setItem('DocumentStatus', 'true');
        }
        else{
          localStorage.setItem('DocumentStatus', 'false');
        } 
        console.log('DocStatus', this.docStatus);
      }
    }, (error: HttpErrorResponse) => {
      this.spinnerService.hide();
      console.log(error.message);
    })
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitNotiSidebarChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }
  logout() {
    
    localStorage.clear();
    this.authService.logout();
  }
}
